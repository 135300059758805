// Experience.js
import React, { useEffect, useState } from 'react';

const Experience = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkVisibility = () => {
      const windowHeight = window.innerHeight;
      const sectionTop = document.getElementById('experience').getBoundingClientRect().top;
      setIsVisible(sectionTop < windowHeight * 0.8);
    };
    window.addEventListener('scroll', checkVisibility);
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  return (
    <>
      <div id="experience" className="experience-section">
        <h2 className="heading">Experience</h2>
        <div className="experiences-list">
          {experiences.map((exp, index) => (
            <div className={`experience-entry ${isVisible ? 'visible' : ''}`} key={index}>
              <h3 className="experience-title">{exp.company}</h3>
              <p className="experience-role">
                {exp.role} - <span className="experience-dates">{exp.location} | {exp.date}</span>
              </p>
              <p className="experience-skills">{exp.skills.join(" | ")}</p>
              <ul className="experience-details">
                {exp.details.map((detail, idx) => <li key={idx}>{detail}</li>)}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div id="publications" className="publications-section">
        <h2 className="heading">📚 Publications</h2>
        <div className="publication-cards">
          {publications.map((pub, index) => (
            <div className="parent" key={index}>
              <div className="card pub-card">
                <div className="glass"></div>
                <div className="content">
                  <span className="title"><strong>{pub.acronym}</strong> – {pub.title}</span>
                </div>
                <div className="bottom pub-info">
                  <div className="pub-meta">
                    <div className="venue">{pub.conference}</div>
                    <div className="status">{pub.status} • {pub.year}</div>
                  </div>
                  {pub.link && (
                    <div className="view-more">
                      <a className="view-more-button" href={pub.link} target="_blank" rel="noopener noreferrer">View Paper</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const experiences = [
  {
    company: "Apple Inc.",
    role: "Software Engineer Intern (Incoming)",
    location: "San Diego, CA",
    date: "Summer 2025",
    skills: ["CoreOS Team", "Systems Engineering", "Software Development"],
    details: ["Incoming SWE Intern at Apple on the CoreOS team for Summer 2025."]
  },
  {
    company: "McAuley Lab",
    role: "Multimodal AI Research Assistant",
    location: "San Diego, CA",
    date: "Jan 2024 – Present",
    skills: ["Generative Models", "Large Language Models", "Representation Learning"],
    details: [
      "Created and fine-tuned a generative model for fine-grained, time-aware music understanding using LLMs and augmentation techniques.",
      "Optimized LoRa finetuning of the SALMONN-7B model on 2 NVIDIA RTX A6000 GPUs.",
      "Evaluated models using BLEU, METEOR, ROUGE, and BERTScore metrics.",
      "Fine-tuning VTime LLM for video visual prompting tasks.",
      "Collaborating on a proactive recommendation system for Netflix."
    ]
  },
  {
    company: "UC San Diego, CSE Department",
    role: "Graduate Teaching Assistant",
    location: "San Diego, CA",
    date: "Mar 2025 – Present",
    skills: ["CSE 153", "Machine Learning for Music", "Instruction"],
    details: [
      "Teaching Assistant for CSE 153: Machine Learning for Music (Spring 2025)",
      "Holding office hours and assisting with coding challenges and course material.",
      "Supporting Prof. Julian McAuley with assignment design and grading."
    ]
  },
  {
    company: "UC San Diego, CSE Department",
    role: "Instructional Assistant | Tutor",
    location: "San Diego, CA",
    date: "Apr 2023 – Mar 2025",
    skills: ["Advanced Data Structures", "Tutoring"],
    details: [
      "Tutored upper division Advanced Data Structures (CSE 100) to 500+ students.",
      "Held 8 office hours weekly and managed ~350 student support tickets per quarter."
    ]
  },
  {
    company: "Fair Data Informatics (FDI) Lab",
    role: "Student Software Engineer",
    location: "San Diego, CA",
    date: "Mar 2023 – Present",
    skills: ["Angular.js", "Pandas", "NumPy", "Scikit-learn"],
    details: [
      "Redesigned Foundry Dashboard architecture for scalable biomedical ETL systems.",
      "Developed resource ingestion protocols for EC2-hosted clients.",
      "Worked on reproducibility analysis with Scikit-learn and Python tools."
    ]
  },
  {
    company: "Orektic Solutions LLP",
    role: "Software Engineer Intern",
    location: "Remote",
    date: "Jun 2023 – Sep 2023",
    skills: ["TypeScript", "Cucumber.js", "Selenium", "Cypress"],
    details: [
      "Built UI Testing framework using TypeScript, Cucumber.js, and Selenium.",
      "Improved automation coverage and cross-browser test reporting with Cypress and Jira integration."
    ]
  },
  {
    company: "IPMD, Inc.",
    role: "Front-End Developer Intern",
    location: "Berkeley, CA",
    date: "Jul 2022 – Sep 2022",
    skills: ["React.js", "TypeScript", "Node.js"],
    details: [
      "Developed frontend web apps for Project M using React and Node.js.",
      "Built responsive, cross-platform UI integrated with live API data."
    ]
  }
];


const publications = [
  {
    acronym: "FUTGA-MIR",
    title: "Fine-grained Music Understanding via Temporally-enhanced Generative Augmentation",
    conference: "ICASSP 2025 (IEEE)",
    status: "Accepted",
    year: "2025",
    link: "https://ieeexplore.ieee.org/abstract/document/10888485"
  },
  {
    acronym: "CoLLAP",
    title: "Contrastive Learning for Long-Form Audio-Text Alignment",
    conference: "ICASSP 2025 (IEEE)",
    status: "Accepted",
    year: "2025",
    link: "https://ieeexplore.ieee.org/abstract/document/10888307"
  },
  {
    acronym: "FUTGA",
    title: "Fine-grained Music Understanding via Temporally-enhanced Generative Augmentation",
    conference: "NLP4MusA @ ISMIR Workshop",
    status: "Accepted",
    year: "2024",
    link: "https://arxiv.org/abs/2407.20445"
  }
];

export default Experience;