import logo from './new-photo.jpg';
import { FaGithub, FaLinkedin, FaGraduationCap } from 'react-icons/fa'; // Added Google Scholar icon

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  section.scrollIntoView({ behavior: 'smooth' });
};

const Introduction = () => {
  return (
    <div id="introduction">
      <div className="introduction-container">

        <div>
          <nav>
            <ul>
              <li>
                <a href="#introduction" onClick={() => scrollToSection('introduction')}>
                  Home
                </a>
              </li>
              <li>
                <a href="#experience" onClick={() => scrollToSection('experience')}>
                  Experience
                </a>
              </li>
              <li>
                <a href="#projects" onClick={() => scrollToSection('projects')}>
                  Projects
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => scrollToSection('contact')}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <h3 className='intro'>🔱 CS @ UC San Diego 🔱 </h3>
          <div className="social-icons">
            <a href="https://github.com/namburiamit" target="_blank" rel="noopener noreferrer">
              <FaGithub className="icon github-icon" />
            </a>
            <a href="https://www.linkedin.com/in/amit-namburi/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin className="icon linkedin-icon" />
            </a>
            <a href="https://scholar.google.com/citations?user=vPtFpgIAAAAJ" target="_blank" rel="noopener noreferrer">
              <FaGraduationCap className="icon scholar-icon" />
            </a>
          </div>
        </div>

        <div className="typing-container">
          <div className="typing">Hey, I'm <span>Amit Namburi</span></div>
        </div>

        <div className="about-me-container">
          <div className="image-container">
            <img src={logo} alt="logo" />
          </div>
          <div className="description-container">
          <h3 className="about"> Researcher in Machine Learning & NLP</h3>
            <p className="fade-in">
              I'm Amit Namburi, a graduate student in Computer Science at <span style={{ fontWeight: '600', color: '#1d4ed8' }}>UC San Diego</span>, focusing on multimodal learning, large language models, and recommender systems. I work on applying deep learning to real-world problems in music intelligence, generative modeling, and scalable AI infrastructure.
              <br /><br />
              📌 Currently building <strong>Conversational Recommender Systems</strong> in the music domain using LLMs, incorporating community-level platforms like Reddit to enhance model grounding and relevance.
              <br /><br />
              🎓 I'm a Graduate TA for <strong>CSE 153: Machine Learning for Music</strong>, and an incoming SWE Intern at 🍎 <strong>Apple (CoreOS)</strong> in Summer 2025.
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Introduction;
