import React, { useEffect, useState } from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaPhone } from 'react-icons/fa';
import resume from '../images/Resume.pdf';

const Contact = () => {
    const [showContactDetails, setShowContactDetails] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const contactSection = document.getElementById('contact');
            const windowHeight = window.innerHeight;
            const sectionTop = contactSection.getBoundingClientRect().top;

            setShowContactDetails(sectionTop < windowHeight * 0.8);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleResumeDownload = () => {
        const link = document.createElement('a');
        link.href = resume;
        link.download = 'Resume_Amit_Namburi.pdf';
        link.click();
    };

    return (
        <div id="contact" className="contact-section">
            <h2 className="heading">Contact</h2>
            {showContactDetails && (
                <div className="contact-details">
                    <div className="contact-item">
                        <FaEnvelope className="contact-icon" /> 
                        <span>Email: <a href="mailto:anamburi@ucsd.edu">anamburi@ucsd.edu</a></span>
                    </div>
                    <div className="contact-item">
                        <FaPhone className="contact-icon" /> 
                        <span>Phone: +1 (858) 319-6075</span>
                    </div>
                    <div className="contact-item">
                        <FaLinkedin className="contact-icon" /> 
                        <span>LinkedIn: <a href="https://www.linkedin.com/in/amit-namburi" target="_blank" rel="noopener noreferrer">linkedin.com/in/amit-namburi</a></span>
                    </div>
                    <div className="contact-item">
                        <FaGithub className="contact-icon" /> 
                        <span>GitHub: <a href="https://github.com/namburiamit" target="_blank" rel="noopener noreferrer">github.com/namburiamit</a></span>
                    </div>
                    <button className="resume-button" onClick={handleResumeDownload}>Download Resume</button>
                </div>
            )}
        </div>
    );
};

export default Contact;
